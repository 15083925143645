



















import { Vue, Component } from 'vue-property-decorator';
import Page from '@/components/Page.vue';
import EditCampaign from './EditCampaign.vue';
import { Id } from '../../user/domain/Types';
import { Campaign } from '../domain/Campaign';
import { CampaignService } from '../services/CampaignService';
import CreativeListing from '@/modules/creative/views/CreativeListing.vue';
import { PortalZone } from '@/modules/portal/domain/PortalZone';
import { PortalService } from '@/modules/portal/services/PortalService';

@Component({
    components: {
        Page,
        EditCampaign,
        CreativeListing,
    },
})
export default class ViewCampaign extends Vue {
    private isLoading: boolean = false;
    private campaignId: Id = '';
    private campaign: Campaign | Campaign = {
        id: '',
        name: '',
        advertiser: null,
        budget: 0,
        expectedDelivery: 0,
        expectedClicks: 0,
        creatives: [],
    };

    private async mounted() {
        if (this.$route.params.campaignId) {
            this.campaignId = this.$route.params.campaignId;
        }

        await this.fetchCampaign();
    }

    private async fetchCampaign() {
        this.isLoading = true;
        if (this.campaignId) {
            try {
                this.campaign = await CampaignService.getCampaignBy(
                    this.campaignId,
                    this.$store.getters['sessionStore/token'],
                );
            } catch (e) {
                return this.$router.push('/campaign-listing');
            }
        }
        this.isLoading = false;
    }

    private async refresh() {
        await this.fetchCampaign();
    }
}
