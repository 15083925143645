











import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Card from '@/components/card/Card.vue';
import ListingAddButton from '@/components/ListingAddButton.vue';
import CreativeTable from '../components/CreativeTable.vue';
import { CreativeService } from '../services/CreativeService';
import { CreativeListingData } from '../domain/CreativeListingData';
import { Campaign } from '@/modules/campaign/domain/Campaign';

@Component({
    components: { Card, ListingAddButton, CreativeTable },
})
export default class CreativeListing extends Vue {
    @Prop() private readonly campaign!: Campaign;
    private isLoading: boolean = false;

    private async onCreativeRestore(creative: CreativeListingData) {
        this.isLoading = true;
        try {
            await CreativeService.restore(creative, this.$store.getters['sessionStore/token']);
            this.$emit('refresh');
        } catch {
            this.$notify({
                title: 'Erreur',
                message: 'Erreur lors de la restauration de votre créatif. Veuillez réessayer.',
            });
        }
        this.isLoading = false;
    }

    private async onCreativeDelete(creative: CreativeListingData) {
        this.isLoading = true;
        try {
            await CreativeService.delete(creative, this.$store.getters['sessionStore/token']);
            this.$emit('refresh');
        } catch {
            this.$notify({
                title: 'Erreur',
                message: 'Erreur lors de la suppression de votre créatif. Veuillez réessayer.',
            });
        }
        this.isLoading = false;
    }

    get buttonRoute() {
        if (this.campaign) {
            return `/create-creative/${this.campaign.id}`;
        }
    }
}
