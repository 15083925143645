





















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CampaignInformationCard from '../components/CampaignInformationCard.vue';
import CampaignStatsCard from '../components/CampaignStatsCard.vue';
import { Campaign } from '../domain/Campaign';
import { CampaignService } from '../services/CampaignService';

@Component({
    components: {
            CampaignInformationCard,
            CampaignStatsCard,
        },
})
export default class EditCampaign extends Vue {
    @Prop() private readonly campaign!: Campaign;

    private isLoading: boolean = false;
    private hasSubmitted: boolean = false;

    private async submit() {
        this.hasSubmitted = true;
        if (this.campaign) {
            await this.saveCampaign();
        }
    }

    public rules =  {
        name: [
            { required: true, message: 'Le champs nom est requis!', trigger: 'blur' },
        ],
        advertiser: [
            { required: true, message: 'Le champs annonceur est requis!', trigger: 'blur' },
        ],
        startDate: [
            { required: true, message: 'Le champs date de début est requis!', trigger: 'blur' },
        ],
        endDate: [
            { required: true, message: 'Le champs date de fin est requis!', trigger: 'blur' },
        ],
    };

    private async saveCampaign() {
        this.isLoading = true;
        const form: any = this.$refs.campaignForm;
        form.validate( async (valid: boolean) => {
            if (valid) {
                try {
                    // Le non null est vérifié plus haut.
                    // @ts-ignore
                    this.campaign.advertiserId = this.campaign.advertiser.id;
                    await CampaignService.save(this.campaign, this.$store.getters['sessionStore/token']);
                    this.$notify.success({
                        title: 'Succès',
                        message: 'Les informations ont été mises à jours',
                    });
                } catch {
                    this.$notify.error({
                        title: 'Erreur',
                        message: 'Erreur lors de la sauvegarde de la campagne.' +
                            ' Veuillez vérifier vos champs et réessayer.',
                    });
                }
                this.isLoading = false;
            } else {
                this.$notify.error({
                    title: 'Erreur',
                    message:
                        'Il ya des erreurs. Veuillez vérifier vos champs.',
                });
                this.isLoading = false;
                return false;
            }
        });
    }
}
