




import { Component, Vue, Prop } from 'vue-property-decorator';
import { MediaType } from '../domain/MediaType';
import { CreativeListingData } from '../domain/CreativeListingData';

@Component
export default class CreativeTypeTag extends Vue {
    @Prop() private readonly creative!: CreativeListingData;

    get type() {
        if (this.creative.type === MediaType.IMG) {
            return 'success';
        }

        return 'primary';
    }

    get text() {
        if (this.creative.type === MediaType.IMG) {
            return 'Image';
        }

        return 'Video';
    }
}
