



























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CreativeListingData } from '../domain/CreativeListingData';
import { configuration } from '../../../config';
import CreativeTypeTag from './CreativeTypeTag.vue';
import { MediaType } from '../domain/MediaType';
import CreativeActiveTag from './CreativeActiveTag.vue';

@Component({
    components: { CreativeTypeTag, CreativeActiveTag },
})
export default class CreativeTable extends Vue {
    @Prop() private readonly creatives!: CreativeListingData[];
    private readonly mediaUrl: string = configuration.MEDIA_URL;

    private editCreative(creative: CreativeListingData) {
        this.$router.push(`/view-creative/${creative.id}`);
    }

    private restoreCreative(creative: CreativeListingData) {
       this.$confirm('Voulez vous vraiment restaurer ce créatif ?', 'Attention').then(() => {
            this.$emit('restore', creative);
        }).catch(() => null);
    }

    private deleteCreative(creative: CreativeListingData) {
        this.$confirm('Voulez vous vraiment archiver ce créatif ?', 'Attention').then(() => {
            this.$emit('delete', creative);
        }).catch(() => null);
    }

    private getMediaUrlFor(creative: CreativeListingData) {
        if (creative.type === MediaType.VID) {
            return `${this.mediaUrl}/video-placeholder.jpg`;
        }
        return `${this.mediaUrl}/${creative.media}`;
    }
}
