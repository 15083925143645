




import { Component, Vue, Prop } from 'vue-property-decorator';
import { CreativeListingData } from '../domain/CreativeListingData';
import { CreativeState } from '@/modules/creative/domain/CreativeState';

@Component
export default class CreativeActiveTag extends Vue {
    @Prop() private readonly creative!: CreativeListingData;
    private state: any;

    get type() {
        if (this.creative.deleted) {
            return 'warning';
        }

        return 'success';
    }

    get text() {
        if (this.creative.deleted) {
            return 'Inactive';
        }
        this.state  = this.creative.state;
        return CreativeState[this.state];
    }
}
